<template>
  <div class="info-wrap">
    <a-spin :spinning="loadding">
      <a-form
        class="form-box"
        layout="vertical"
        ref="formRef"
        :model="formData"
        :colon="false"
      >
        <div class="btns">
          <a-button v-show="!isEdit" @click="isEdit = true">{{
            $t("BN_Comm_Edit")
          }}</a-button>
          <!-- 编辑 -->
          <a-button type="primary" v-show="isEdit" @click="submit">{{
            $t("CM_Confirm")
          }}</a-button>
          <!-- 确定 -->
          <a-button v-show="isEdit" @click="isEdit = false">{{
            $t("CM_Cancel")
          }}</a-button>
          <!-- 取消 -->
        </div>
        <a-row :gutter="16" v-if="fieldList.length">
          <a-col :span="12">
            <!-- 头像 -->
            <a-form-item :label="$t('CM_Header')" name="portrait">
              <div class="avatar-box">
                <div class="left">
                  <img :src="imageUrl" v-if="imageUrl" />
                  <img src="@/assets/image/teacher_avatar.png" v-else />
                </div>
                <div class="right" v-show="isEdit">
                  <div class="btn">
                    <a-button>{{ $t("upload.upload_img") }}</a-button>
                    <!-- 上传图片 -->
                    <input
                      class="up-input"
                      ref="uploadInput"
                      type="file"
                      accept="image/jpg, image/jpeg, image/png, image/gif"
                      @change="selectFile"
                    />
                  </div>
                  <p>
                    <InfoCircleOutlined style="margin-right: 5px" />{{
                      $t("teacher.avatar_tip")
                    }}
                  </p>
                  <!-- 图片上传比例为3:4，最佳上传分辨率300*400 -->
                </div>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 讲师等级 -->
            <a-form-item
              :label="$t('teacher.lecturer_level')"
              name="gender"
              style="margin-top: 88px"
            >
              <a-input :value="formData.levelName" disabled>
                <template #prefix>
                  <img
                    class="prefix-icon"
                    :src="formData.levelPicture"
                    alt="icon"
                  />
                </template>
              </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 姓名 -->
            <a-form-item :label="$t('teacher.name')" name="tel">
              <OpenData
                type="userName"
                :openid="formData.lecturerName"
                v-if="
                  (platformConfig.platform == 26 ||
                    platformConfig.platform == 31) &&
                  formData.lecturerType == 1
                "
              />
              <a-input v-else :value="formData.lecturerName" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 讲师分类 -->
            <a-form-item
              :label="$t('teacher.lecturer_classification')"
              name="tel"
            >
              <a-input :value="formData.folderName" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 性别 -->
            <a-form-item :label="$t('teacher.sex')" name="gender">
              <a-input
                :value="
                  formData.gender == 1
                    ? $t('teacher.man_woman', 1)
                    : formData.gender == 2
                    ? $t('teacher.man_woman', 2)
                    : ''
                "
                disabled
              />
              <!-- 男 女 -->
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 手机号 -->
            <a-form-item :label="$t('teacher.mobile')" name="mobile">
              <a-input :value="formData.mobile" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 邮箱 -->
            <a-form-item :label="$t('teacher.email')" name="email">
              <a-input :value="formData.email" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 座机号 -->
            <a-form-item :label="$t('teacher.tel')" name="tel">
              <a-input :value="formData.tel" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 职务 -->
            <a-form-item :label="$t('teacher.position')" name="positionRank">
              <a-input :value="formData.positionRank" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <!-- 出生日期 -->
            <a-form-item :label="$t('teacher.birthday')" name="birthday">
              <a-input :value="formData.birthday" disabled />
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="defaultFieldObj.expertTitle.dataDisplay == 1">
            <!-- 专家称号 -->
            <a-form-item :label="$t('teacher.expertTitle')" name="expertTitle">
              <a-tag
                v-for="tag in formData.expertTitleArr"
                :closable="isEdit"
                @close="handleClose(tag)"
                :key="tag"
              >
                {{ tag }}
              </a-tag>
              <a-input
                v-if="inputVisible"
                v-show="isEdit"
                ref="inputRef"
                type="text"
                size="small"
                :maxlength="20"
                :style="{ width: '78px' }"
                v-model:value="inputValue"
                @blur="handleInputConfirm"
                @keyup.enter="handleInputConfirm"
              />
              <a-tag
                v-else
                v-show="isEdit"
                @click="showInput"
                style="background: #fff; border-style: dashed"
              >
                <plus-outlined />
                {{ $t("teacher.add_title") }}
                <!-- 添加称号 -->
              </a-tag>
            </a-form-item>
          </a-col>
          <a-col :span="24" v-if="defaultFieldObj.intro.dataDisplay == 1">
            <!-- 讲师简介 -->
            <a-form-item :label="$t('teacher.lecturer_intro')" name="intro">
              <a-textarea
                :rows="4"
                v-model:value="formData.intro"
                :placeholder="!isEdit ? '' : $t('teacher.lecturer_intro_p')"
                allowClear
                :maxlength="500"
                :showCount="isEdit"
                :disabled="!isEdit"
              />
              <!-- 请输入讲师简介 -->
            </a-form-item>
          </a-col>
          <template v-for="item in fieldList" :key="item.fieldKey">
            <template v-if="item.defaultField == 2 && item.dataDisplay == 1">
              <!-- 1:文本框 -->
              <a-col :span="12" v-if="item.fieldType == 1">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    message: $t('PleaseEnter') + item.fieldName,
                    trigger: 'change',
                  }"
                >
                  <a-input
                    v-model:value="formData[item.fieldKey]"
                    :placeholder="
                      !isEdit
                        ? ''
                        : `${$t('PleaseEnter')}${item.fieldName}${$t(
                            'WithinNWords',
                            { total: 50 }
                          )}`
                    "
                    allowClear
                    :maxlength="50"
                    :disabled="!isEdit"
                  />
                </a-form-item>
              </a-col>
              <!-- 2:下拉框 -->
              <a-col :span="12" v-if="item.fieldType == 2">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    message: $t('PleaseSelect') + item.fieldName,
                    trigger: 'change',
                    type: 'object',
                  }"
                >
                  <a-select
                    allowClear
                    labelInValue
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    v-model:value="formData[item.fieldKey]"
                    :placeholder="
                      !isEdit ? '' : `${$t('PleaseSelect')}${item.fieldName}`
                    "
                    :options="item.fieldOptions"
                    :disabled="!isEdit"
                  />
                </a-form-item>
              </a-col>
              <!-- 4:数值 -->
              <a-col :span="12" v-if="item.fieldType == 4">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    type: 'number',
                    message: $t('PleaseEnter') + item.fieldName,
                    trigger: 'change',
                  }"
                >
                  <a-input-number
                    allowClear
                    v-model:value="formData[item.fieldKey]"
                    :placeholder="
                      !isEdit
                        ? ''
                        : `${$t('PleaseEnter')}${item.fieldName}(${
                            item.len
                              ? $t('CanInputNDecimalPlace', { total: item.len })
                              : $t('PleaseEnterInteger')
                          })`
                    "
                    :precision="item.len"
                    style="width: 100%"
                    :disabled="!isEdit"
                  />
                </a-form-item>
              </a-col>
              <!-- 5:日期组件 -->
              <a-col :span="12" v-if="item.fieldType == 5">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    message: $t('PleaseSelect') + item.fieldName,
                    trigger: 'change',
                  }"
                >
                  <a-date-picker
                    :getCalendarContainer="
                      (triggerNode) => triggerNode.parentNode
                    "
                    valueFormat="YYYY-MM-DD"
                    v-model:value="formData[item.fieldKey]"
                    allowClear
                    style="width: 100%"
                    :placeholder="
                      !isEdit ? '' : `${$t('PleaseSelect')}${item.fieldName}`
                    "
                    :disabled="!isEdit"
                  />
                </a-form-item>
              </a-col>
              <!-- 6:文本域 -->
              <a-col :span="24" v-if="item.fieldType == 6">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    message: $t('PleaseEnter') + item.fieldName,
                    trigger: 'change',
                  }"
                >
                  <a-textarea
                    :rows="4"
                    v-model:value="formData[item.fieldKey]"
                    :placeholder="
                      !isEdit ? '' : `${$t('PleaseEnter')}${item.fieldName}`
                    "
                    allowClear
                    :maxlength="500"
                    :showCount="isEdit"
                    :disabled="!isEdit"
                  />
                </a-form-item>
              </a-col>
              <!-- 7:时间组件 -->
              <a-col :span="12" v-if="item.fieldType == 7">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    message: $t('PleaseEnter') + item.fieldName,
                    trigger: 'change',
                  }"
                >
                  <a-date-picker
                    :getCalendarContainer="
                      (triggerNode) => triggerNode.parentNode
                    "
                    valueFormat="YYYY-MM-DD HH:mm"
                    :show-time="{ format: 'HH:mm' }"
                    format="YYYY-MM-DD HH:mm"
                    v-model:value="formData[item.fieldKey]"
                    allowClear
                    style="width: 100%"
                    :placeholder="
                      !isEdit ? '' : `${$t('PleaseSelect')}${item.fieldName}`
                    "
                    :disabled="!isEdit"
                  />
                </a-form-item>
              </a-col>
              <!-- 8:树 -->
              <a-col :span="12" v-if="item.fieldType == 8">
                <a-form-item
                  :name="item.fieldKey"
                  :label="item.fieldName"
                  :rules="{
                    required: item.dataRequired == 1,
                    message: $t('PleaseEnter') + item.fieldName,
                    trigger: 'change',
                    type: item.isCheck == 1 ? 'array' : 'object',
                  }"
                >
                  <a-tree-select
                    allowClear
                    labelInValue
                    v-model:value="formData[item.fieldKey]"
                    style="width: 100%"
                    :dropdown-style="{ maxHeight: '30vh', overflow: 'auto' }"
                    :tree-data="item.fieldOptions"
                    :placeholder="!isEdit ? '' : $t('PleaseSelect')"
                    treeNodeFilterProp="title"
                    :multiple="item.isCheck == 1"
                    :treeCheckable="item.isCheck == 1"
                    :showCheckedStrategy="
                      item.isSelectChild == 1 ? 'SHOW_CHILD' : 'SHOW_ALL'
                    "
                    :replaceFields="{
                      children: 'children',
                      title: 'optionValue',
                      key: 'optionKey',
                      value: 'optionKey',
                    }"
                    treeDefaultExpandAll
                    :disabled="!isEdit"
                  ></a-tree-select>
                </a-form-item>
              </a-col>
            </template>
          </template>
        </a-row>
      </a-form>
    </a-spin>
    <!-- 上传头像 -->
    <a-modal
      v-model:visible="showDialog"
      :title="$t('teacher.upload_avatar')"
      :maskClosable="false"
    >
      <VuePictureCropper
        :boxStyle="{
          width: '300px',
          height: '400px',
          backgroundColor: '#f8f8f8',
          margin: 'auto',
        }"
        :img="pic"
        :options="{
          viewMode: 1,
          dragMode: 'move',
          aspectRatio: 300 / 400,
          cropBoxResizable: false,
        }"
        :presetMode="{
          mode: 'fixedSize',
          width: 300,
          height: 400,
        }"
      />
      <template #footer>
        <a-button @click="showDialog = false">{{ $t("CM_Cancel") }}</a-button>
        <!-- 取消 -->
        <a-button type="primary" @click="getResult">{{
          $t("teacher.crop")
        }}</a-button>
        <!-- 裁切 -->
      </template>
    </a-modal>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import {
  getCurrentInstance,
  reactive,
  ref,
  toRefs,
  nextTick,
  computed,
} from "vue";
import { useStore } from "vuex";
import { fieldConfigList } from "@/api/other";
import { getTeacher, editTeacher } from "@/api/teacher";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import request from "@/utils/request";
import { uploadUrl, handelSemicolonWrap } from "@/utils/tools";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    VuePictureCropper,
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const state = reactive({
      platformConfig: computed(() => store.getters.platformConfig),
      companyInfo: computed(() => store.getters.companyInfo),
      isEdit: false,
      imageUrl: "",
      pic: "",
      showDialog: false,
      loadding: false,
      inputVisible: false,
      inputValue: "",
      fieldList: [],
      extendFields: {},
      defaultFieldObj: {},
      formData: {
        levelPicture: "",
        levelName: "",
        lecturerName: "",
        folderName: "",
        portrait: "",
        gender: 1,
        mobile: "",
        email: "",
        tel: "",
        birthday: "",
        positionRank: "",
        intro: "",
        motto: "",
        teachingDirection: "",
        expertAreas: "",
        expertTitle: "",
        expertTitleArr: [],
        researchAreas: "",
        workExperience: "",
        others: "",
      },
    });

    const getLecturer = () => {
      getTeacher().then((res) => {
        let d = res.data;
        d.fields = d.fields || [];
        d.fields.map((item) => {
          d[item.fieldKey] = item.fieldValue;
          state.formData[item.fieldKey] = null;
        });
        Object.keys(d).forEach((key) => {
          if (state.extendFields[key]) {
            let item = state.extendFields[key];
            if (item.fieldType == 2 || item.fieldType == 8) {
              if (d[key]) d[key] = JSON.parse(d[key]);
            }
            if (item.fieldType == 4) {
              if (d[key]) d[key] = Number(d[key]);
            }
          }
        });
        state.imageUrl = d.portrait;
        Object.keys(state.formData).forEach((key) => {
          if (key == "birthday") {
            let b = d[key] ? d[key] + "" : "";
            state.formData[key] = b
              ? b.slice(0, 4) + "-" + b.slice(4, 6) + "-" + b.slice(6)
              : "";
          } else if (key == "expertTitleArr") {
            state.formData[key] = d.expertTitle ? d.expertTitle.split(",") : [];
          } else {
            state.formData[key] = d[key];
          }
        });
      });
    };

    fieldConfigList(7).then((res) => {
      let d = res.data || [];
      let obj = {};
      d.forEach((item) => {
        if (item.defaultField == 2) {
          if (item.fieldType == 2) {
            item.fieldOptions.map((opt) => {
              opt.label = opt.optionValue;
              opt.value = opt.optionKey;
            });
          }
          state.extendFields[item.fieldKey] = item;
        } else {
          obj[item.fieldKey] = item;
        }
      });
      state.defaultFieldObj = obj;
      state.fieldList = d;
      getLecturer();
    });

    const uploadInput = ref(null);
    let blobURL = "";
    const selectFile = (e) => {
      blobURL = "";
      const target = e.target;
      const { files } = target;
      if (!files) return;
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        state.pic = String(reader.result);
        state.showDialog = true;
        if (!uploadInput.value) return;
        uploadInput.value.value = "";
      };
    };

    const getResult = async () => {
      const blob = await cropper.getBlob();
      const file = await cropper.getFile();
      try {
        blobURL = URL.createObjectURL(blob);
      } catch (e) {
        blobURL = "";
      }
      state.showDialog = false;
      state.imageUrl = blobURL;
      let formData = new FormData();
      formData.append("file", blob, file.name);
      request
        .post(uploadUrl + "study/me/upload/avatar", formData)
        .then((res) => {
          state.formData.portrait = res.data;
        });
    };

    const inputRef = ref();
    const handleClose = (removedTag) => {
      state.formData.expertTitleArr = state.formData.expertTitleArr.filter(
        (tag) => tag !== removedTag
      );
    };

    const showInput = () => {
      state.inputVisible = true;
      nextTick(() => {
        inputRef.value.focus();
      });
    };

    const handleInputConfirm = () => {
      const inputValue = state.inputValue;
      let tags = state.formData.expertTitleArr;

      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue];
      }
      state.formData.expertTitleArr = tags;
      Object.assign(state, {
        inputVisible: false,
        inputValue: "",
      });
    };

    const formRef = ref(null);
    const submit = () => {
      formRef.value.validate().then(() => {
        state.loadding = true;
        let params = JSON.parse(JSON.stringify(state.formData));
        if (params.expertTitleArr) {
          params.expertTitle = params.expertTitleArr.join(",");
        }
        let customFieldMap = {};
        Object.keys(params).forEach((key) => {
          if (state.extendFields[key]) {
            let item = state.extendFields[key];
            if (item.fieldType == 2 || item.fieldType == 8) {
              customFieldMap[key] = JSON.stringify(params[key]);
            } else if (item.fieldType == 4) {
              customFieldMap[key] = String(params[key]);
            } else {
              customFieldMap[key] = params[key];
            }
          }
        });
        params.customFieldMap = customFieldMap;
        editTeacher(params).then((res) => {
          if (res.ret == 0) {
            proxy.$message.success($t("CM_Success"));
            // 操作成功
            state.isEdit = false;
          }
          state.loadding = false;
        });
      });
    };

    return {
      ...toRefs(state),
      uploadInput,
      selectFile,
      getResult,
      inputRef,
      handleClose,
      showInput,
      handleInputConfirm,
      formRef,
      submit,
    };
  },
};
</script>

<style lang="less" scoped>
.info-wrap {
  .mixinWrap(828px);
  .form-box {
    position: relative;
    .btns {
      position: absolute;
      top: 1px;
      right: 1px;
      z-index: 1;
      button {
        margin-left: 10px;
      }
    }
    .ant-form-item {
      margin-bottom: 16px;
      ::v-deep(.ant-form-item-label) {
        & > label {
          color: #999;
        }
      }
      .prefix-icon {
        width: 22px;
        height: 22px;
      }
    }
  }
}
.avatar-box {
  .mixinFlex(space-between; center);
  .left {
    width: 90px;
    height: 120px;
    border-radius: 6px;
    font-size: 0;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    width: calc(100% - 104px);
    .btn {
      width: 88px;
      height: 32px;
      position: relative;
      margin-bottom: 10px;
      .up-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 88px;
        height: 32px;
        opacity: 0;
        background-color: transparent;
        color: transparent;
        cursor: pointer;
      }
    }
    p {
      color: #999999;
      font-size: 12px;
      margin: 0;
    }
  }
}
</style>
